.navbar {
    background: #fff;
    height: 90px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    width: 100%;
  }
  
.logo-img{
  height: 70px;
  margin-right: 20px;
}
.logo-text{
  height: 30px;
  margin-left: 10px;
}
.login-icon{
  height: 30px;
  margin-right: 10px;
  border: 2px solid #f4d8d0;
  border-radius: 30px;

}

.navbar-log-in {
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
  width: 10%;
  text-decoration: none;
  color:#f4cdc4;
  
}

  .navbar-logo {
    color: #7C675B;
    background: #FBE6E1;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    width: 26%;
    height:90px;
  }

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 1.2rem;
  }
  
  .nav-item {
    height: 80px;
    /* width: 80px; */
    padding: 0px 17px;
  }
  .color-red {
    color: #fa5d5d;
  }
  .nav-links {
    color: #7C675B;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0.5rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 3px solid #FBE6E1;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #7C675B;
  }

  .fa-xmark {
    color: #7C675B;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .nevbar{
      height:100%;
    }
    
    /* .NavbarItems {
      position: relative;
    } */
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 90px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: space-evenly;
    }



    .nav-item {
      height: 50px;
      width: 100%;
    }
  
    .nav-menu.active {
      background: #fff;
      left: 0;
      transition: all 0.5s ease;
      opacity: 1;
      z-index: 1;
    }
    
    .logo-img{
      height: 70px;
      margin-left: 5%;
      margin-right: 3%;
      padding:0%;
    }

    .logo-text{
      display: none;
    }
    .login-icon{
      display:none;
    }
    
    .navbar-log-in {
      display:none;
    }

    .navbar-logo {
      position: absolute;
      width:100%;
      justify-content: flex-start;
    }
    
    .nav-links {
      text-align: center;
      padding: 1rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #FBE6E1;
      color: #7C675B;
      border-radius: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      /* margin: 2rem auto; */
      border-radius: 4px;
      width: 100%;
      text-decoration: none;
      background-color: #FBE6E1;
      color: #7C675B;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #FBE6E1;
      color: #7C675B;
      transition: all 0.5s ease;
    }
  }