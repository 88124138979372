* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Raleway','Open Sans';
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.clothes,
.sale,
.sign-up,
.log-in,
.contact,
.events {
  display: flex;
  height: 18vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #FBE6E1;
  font-family: 'Open Sans';
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-color: antiquewhite;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* .products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */
@media screen and (max-width: 960px) {

.clothes,
.sale,
.sign-up,
.log-in,
.contact,
.events {
  display: flex;
  height: 15vh;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-color: #fff;
}
}