.welcome-component {
  position: relative; /* This is the parent container */
  width: 100%; /* Set to the width of your viewport or container */
  height: 130vh; /* Set to the height of your viewport or a fixed height as needed */
  background-color: #fff; /* Adjust the color to match the light pink background */
  display: flex;
  padding-top: 50px;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* padding: 20px; */
}

.pinklogo-img {
  position: absolute;
  /* top: 10%;  */
  /* left: 10%; */
  width: 25.7%; /* Adjust the size according to your image */
  height: 90vh; /* Maintain aspect ratio */
  object-fit: contain;
  background-color: #FBE6E1;
  padding:10px;
  z-index: 8;
}


.content {
  position: absolute;
  /* top: 50px; */
  right: 15%;
  /* transform: translate(-50%, -50%);  */
  width: 60%; /* Adjust the width as needed */
  max-width: 600px; /* Adjust as needed */
}

.welcome-text {
  margin-bottom: 20px;
  top:10%; /* Adjust spacing between welcome text and special offer */
  /* Additional styling for text */
}
.welcome-heading {
  text-align: left;
  padding:20px;
  font-size: 28px;
  font-weight:bold;
}
.welcome-content{
    font-size: 18px;
    color:#7C675B;
    line-height: 35px;
    width: 80%;
    margin-left: 20%;
    padding-bottom: 40px;
}

.welcome-button{
  padding:8px 20px;
  border-radius: 2px;
  outline:none;
  border:none;
  cursor:pointer;
  background-color: #fff;
  color: #574c4c;
  border: 2px solid #E2AD9F;
  position:absolute;
  right:0%;
}

.special-offer{
  /* width: 70%; 
  height: 100%;  */
}
.special-offer-img {
  position: absolute;
  /* bottom: 20px; 
  right: 20px;  */
  width: 100%; 
  height: 55vh;
  object-fit: cover;
  top:150%;
  justify-content: center;
  align-content: center;
  z-index: 5;
}

.decorative-block {
  position: absolute;
  bottom:25vh;
  right: 0;
  width: 80%; /* Adjust to your preference */
  height:55vh; /* Stretch to the height of the container */
  background-color: #B99D8E; 
  /* z-index: ; */
}

/* Additional styling for buttons, text, etc. */
