.location-container {
    text-align: center;
    color: #7C675B;
    padding: 20px;
  }

  .contact-container {
    text-align: center;
    color: #7C675B;
    padding: 20px;
  }

  h2{
    margin: 30px 0px;
  }
  p{
    margin: 10px 0px;
  }
  
  .email-address {
    font-weight: bold;
    color:#E2AD9F;
  }