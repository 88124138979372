.banner {
    width: 100%;
    height:40vh; /* Adjust based on your needs */
    background-color: #fff;
    position: relative;
}

.banner-logo,
.banner-f {
    position: absolute;
    width: 100px; /* Adjust based on your needs */
    height: auto;
    /* Add additional styling and positioning */
}

.banner-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-text {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    /* Add additional styling */
}

.banner-text h1 {
    margin: 30px 20px;
    height: 10vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 1000;
    color: #E2AD9F;
}

.banner-text h5 {
    color: #7C675B;
    margin: 0px 0 25px 0px;
    font-size: 22px;
    line-height: 28px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media only screen and (max-width: 430px) {
    .banner-text h1 {
        margin: 30px 20px;
        height: 10vh;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        font-weight: 700;
        color: #E2AD9F;
    }
    
    .banner-text h5 {
        color: #7C675B;
        margin: 0px 0 25px 0px;
        font-size: 18px;
        line-height: 24px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

