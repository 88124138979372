.ADcontainer {
    position: relative;
    display: block;
    width: 100%;
    height:85vh;
    background-color: #fff;
    align-content: center;
  }

  .wrapper {
    width: 100%;
    height:100%;
  }
  
  .color_block {
    background-color: #B99D8E;
    width: 40%; 
    height: 50%; 
    position: absolute;
    top: 10%;
    left: 17%;
  }

  .ad {
    width: 50%; 
    height: 65%; 
    position: absolute;
    z-index: 2;
    top: 20%;
    right: 17%;
  }

  .Products__item__img{
    object-position: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* position: absolute; */
}
.Products__item__link:hover {
    transform: scale(1.1);
  }
  