.slider {
    position: relative;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 100%;
    height: 70vh;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #E2AD9F;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #E2AD9F;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  
  @media only screen and (max-width: 960px) {
    .right-arrow {
        position: absolute;
        top: 50%;
        right: 15%;
        font-size: 3rem;
        color: #FBE6E1;
        z-index: 10;
        cursor: pointer;
        user-select: none;
      }
      
      .left-arrow {
        position: absolute;
        top: 50%;
        left: 15%;
        font-size: 3rem;
        color: #FBE6E1;
        z-index: 10;
        cursor: pointer;
        user-select: none;
      }


  }

  @media only screen and (max-width: 430px) {

    .image {
        width: 300px;
        height: 40vh;
        border-radius: 10px;
        object-fit: cover;
      }

  }