.memberships__item__img{
    object-position: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
.memberships__item__img:hover {
    transform: scale(1.1);
  }

  .memberships__item__pic-wrap {
    position: relative;
    width: 100%;
    height: 20vh;
    /* padding-top: 100%; */
    overflow: hidden;
  }

  .memberships__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    top: 0;
    margin-left:0;
    padding: 6px;
    /* max-width: calc((100%) - 60px); */
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #7C675B;
    box-sizing: border-box;
  }
  

  .memberships__item__link {
    display: flex;
    flex-flow: column;
    /* max-width: 17%; */
    overflow: hidden;
    text-decoration: none;
    justify-content: stretch;
    align-items:center;
    margin-bottom: 40px;
  }



  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }

  .memberships__item__info{
    max-width: 90%;
    padding: 20px 0 20px 0;
  }
  
  .memberships__item__priceinfo{
    max-width: 90%;
    padding: 20px 0 30px 0;
  }

  .memberships__item__text, .memberships__item__price {
    display:flex;
    color: #7C675B;
    font-size: 18px;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .memberships__item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    /* Only space-enenly worked, none of the others.I would like to have space between, how?*/
    margin: 0 25px 0 25px;
    width: 25%;
  }

  .memberships__items {
    margin-bottom: 0;
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
  }

  .memberships__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    /* max-width: 1120px; */
    width: 90%;
    margin: 0 auto;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .memberships__item__text, .memberships__item__price {
        font-size: 15px;
        line-height: 20px;
      }
      .memberships__item__info{
        max-width: 90%;
        padding: 10px 0 10px 0;
      }
      
      .memberships__item__priceinfo{
        max-width: 90%;
        padding: 10px 0 20px 0;
      }
      .memberships__items {
        width: 95%;
        /* margin: 30px 0 0 0; */
      }

  }  
  
  @media only screen and (max-width: 990px) {
    .memberships__item__text, .memberships__item__price {
        font-size: 15px;
        line-height: 20px;
      }
      .memberships__item__info{
        max-width: 95%;
        padding: 10px 0 10px 0;
      }
      
      .memberships__item__priceinfo{
        max-width: 95%;
        padding: 10px 0 20px 0;
      }
      .memberships__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 20px 0 20px;
        width: 19%;
      }
  }
  
  @media only screen and (max-width: 960px) {
    .memberships__items {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        justify-content: center;
        align-items: center;
        width: 95%;
        /* margin: 30px 0 0 0; */
      }

      .memberships__item {
        margin: 0 25px 0 25px;
        width: 95%;
      }

      .memberships__item__pic-wrap {
        height: 25vh;
      }

      .memberships__item__text, .memberships__item__price {
        font-size: 18px;
        line-height: 20px;
      }

      .memberships__item__info{
        max-width: 90%;
        padding: 20px 0 10px 0;
      }

      .memberships__item__link {
        /* display: flex;
        flex-flow: column;
        max-width: 17%;
        overflow: hidden; */
        text-decoration: none;
        justify-content: stretch;
        align-items:center;
        margin-bottom: 40px;
      }

  }
  