.Videocontainer {
    position: relative;
    display: block;
    width: 100%;
    height:85vh;
    background-color: #fff;
    align-content: center;
    padding-top: 20px;
  }


  .wrapperv {
    width: 100%;
    height:100%;
  }
  
  .color_blockv {
    background-color: #B99D8E;
    width: 70%; 
    height: 60%; 
    position: absolute;
    top: 35%;
    left: 0%;
  }

  .adv {
    width: 70%; 
    height: 70%; 
    position: absolute;
    z-index: 2;
    top: 12%;
    right: 10%;
  }

  .Products__item__video{
    object-position: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
/* .Products__item__link:hover {
    transform: scale(1.1);
  }
   */