.contact-container {
    text-align: center;
    color: #7C675B;
    padding: 20px;
  }

  h2{
    margin: 30px 0px;
  }
  p{
    margin: 10px 0px;
  }
  
  .email-address {
    font-weight: bold;
    color:#E2AD9F;
  }
  
  .qr-codes {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .qr-code {
    margin: 0 30px;
  }
  
  .qr-code img {
    width: 150px; /* You can adjust this as needed */
    height: auto;
    margin-bottom: 10px;
  }
  
  .live-chat-info {
    font-style: italic;
    margin-top: 20px;
  }
  